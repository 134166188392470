<template>
  <!-- 找回密码 -->
  <div class="findPassword">
    <img class="semi-circle" src="@/assets/image/login/Ellipse 1715.png" alt="">
    <div class="main-box">
      <div class="box_main">
        <img class="round" src="@/assets/image/login/Ellipse 1717.png" alt="">
        <findPassword :state=1></findPassword>
      </div>
    </div>
    
    <!-- <findPassword v-if="showFindPop" :state=2 @closeFindPop="closeFindPop()"></findPassword> -->
    <!-- 背景 -->
    <div class="foot">
      <div class="waves">
        <i class="public-i first"></i>
        <i class="public-i second"></i>
      </div>
    </div>
  </div>
</template>

<script>
import findPassword from "@/components/login/findPassword.vue";
export default {
  // 可用组件的哈希表
  components: {
    findPassword
  },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      showFindPop: false, //找回密码
    }
  },
  // 事件处理器
  methods: {
    // 关闭弹框
    closeFindPop() {
      this.showFindPop = false
    },
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  .findPassword {
    position: relative;
    padding-top: 230px;
    // padding-bottom: 100px;
    padding-bottom: 250px;
    background-color: #fff;
    .semi-circle{
      position: absolute;
      top: 0;
      left: -40px;
    }
  }
  .main-box {
    position: relative;
    z-index: 2;
    text-align: center;
    min-height: auto;
    
    .box_main{
      display: inline-block;
      position: relative;
      .round{
        position: absolute;
        z-index: 0;
        right: -120px;
        top: -95px;
        width: 203px;
        height: 203px;
        animation: rotate 4s linear infinite;
      }
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
  .foot{
    position: fixed;
    bottom: -2px;
    height: 218px;
    width: 100%;
    z-index: 0;
    .waves{
      height: 100%;
      overflow: hidden;
      position: relative;
      .public-i{
        display: block;
        height: 100%;
        position: absolute;
        width: 4694px;
        z-index: 3;
        left: 0;
      }
      .first{
        background: url('../../assets/image/login/foot-bg1.png') top/cover;
        animation: freames1 20s cubic-bezier(.14,1,.87,.13) infinite alternate;
      }
      .second{
        background: url('../../assets/image/login/foot-bg2.png') top/cover;
        animation: freames2 20s cubic-bezier(.14,1,.87,.13) infinite alternate;
        left: auto;
        right: 0;
      }
      @keyframes freames1 {
        0% {
          transform: translateZ(0)
        }
        100% {
          transform: translate3d(-50%,0,0);
        }
      }
      @keyframes freames2 {
        0% {
          transform: translateZ(0);
        }
        100% {
          transform: translate3d(50%,0,0);
        }
      }
    }
  }
  // 小屏分辨率内容区呈现1200px布局
  @media screen and (max-width: 16000px) {
    .findPassword {
      height: 100vh;
      padding-top: 240px;
      padding-bottom: 60px;
    }
    .main_bg {
      height: 220px;
    }
  }
</style>
